import { useEffect, useState } from "react";
import { FloatComponentType } from "../../types/components";

export default function IntComponent({
	value,
	onChange,
	disabled,
}: FloatComponentType) {
	const [myValue, setMyValue] = useState(value ?? "");
	useEffect(() => {
		if (disabled) {
			setMyValue("");
			onChange("");
		}
	}, [disabled, onChange]);
	return (
		<div className={disabled ? "pointer-events-none cursor-not-allowed w-full" : "w-full"}>
			<input
				onKeyDown={(event) => {
                    if (event.key !== 'Backspace' && event.key !== 'Enter' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && !/^[-]?\d*$/.test(event.key)) {
                        event.preventDefault();
                    }
				}}
				type="number"
				value={myValue}
				className={
					"block w-full form-input dark:bg-gray-900 arrow-hide dark:border-gray-600 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" +
					(disabled ? " bg-gray-200 dark:bg-gray-700" : "")
				}
				placeholder="Type a integer number"
				onChange={(e) => {
					setMyValue(e.target.value);
					onChange(e.target.value);
				}}
			/>
		</div>
	);
}
